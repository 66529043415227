/* eslint-disable */

//HTML tags to be used to replace markdown symbols
const SINGLE_REPLACE_TAGS = ["h1", "h3", "blockquote", "br"];
const MARKDOWN_TAGS = {
  strong: "*",
  b: "*",
  i: "_",
  u: "__",
  s: "~",
  strike: "~",
  highlight: "`",
  blockquote: ">",
  h1: "# ",
  h3: "### ",
  br: "\n",
};

const rules = {
  bold: /(^|\s|[{\[])\*{1,2}(.*?\S *)?\*{1,2}(?=$|\s|[\.,\?:;\-}\]](?:$|\s))/g, // *md*
  italic: /(^|\s|[{\[])\_(.*?\S *)?\_(?=$|\s|[\.,\?:;\-}\]](?:$|\s))/g, // _md_
  underline: /(^|\s|[{\[])\_{2}(.*?\S *)?\_{2}(?=$|\s|[\.,\?:;\-}\]](?:$|\s))/g, // __md__
  strike: /(^|\s|[{\[])\~(.*?\S *)?\~(?=$|\s|[\.,\?:;\-}\]](?:$|\s))/g, // ~md~
  heading: /(?:^|\n)(\#{1,6}) (.*?\S *)(?:\n|$)/gm, // # md
  blockquote: /(?:^|\n)(?:&gt;|>\s|&gt;\s) (.+?)(?:\n|$)/gm, // > md
  monospace: /(^|\s)\`(.*?\S *)?\`(?=$|\s)/g, // `md`
  codeblock: /(^|\s)`{3}((?:(?:.*?$)\n)?(?:[\s\S]*?))`{3}(?=$|\s)/g, // ```md```
  nextline: /(.*)(\n)(.*)/gm, // /n
  line: /(^|[\n])(\*{3,}|\-{3,})(?=$|[\n])(.*)/g, // ---
  link: /(^|\s)\[(.+?)\]\(((?:(?:https?:\/\/(?:www\.)?|www\.)|(?:mailto:)).*?)\)(?:{:(.*)})?/gi, // [md](link)
  image:
    /!\[(.*)\]\((.*?)\s*(?:(?:'|"|&quot;)(.*)(?:'|"|&quot;))?\s*\)(?:{:(.*)})?/gi, // ![md-alt](link title){: vtype="$vtype" height="36px" width="36px"}
  video:
    /(?:\[\!\[(.*)\]\((.*?)\s*(?:(?:'|"|&quot;)(.*)(?:'|"|&quot;))?\s*\)(?:{:(.*)})?]\((.*?)\s*(?:(?:'|"|&quot;)(.*)(?:'|"|&quot;))?\s*\))(?:{:(.*)})?/gi, // [![md-alt](thumbnail title){: vtype="$vtype" height="36px" width="36px"}](link title){: vtype="$vtype" height="36px" width="36px"}
  bullet:
    // /(?:^\s|\n|^)(?:[\-|●|○] (?!\[( |x)\])){1}(.*?\S [\-|●|○])*(?:(?:.*?$)\n)?((?:^\s|\n|^)*(?:[\-|●|○] ){1}(.*?\S [\-|●|○])*(?:(?:.*?$)\n)?)*/gm,
    /(^\s|\n|^)([\*|\-|●|○] )(.*?\S *)*(?:(?:.*?$)\n)?((\s)*([\*|\-|●|○] )(.*?\S *)*(?:(?:.*?$)\n)?)*/gm,
  // /(^\s|\n|^)(\-) (.*?\S -)*(?:(?:.*?$)\n)?((\s)*(\-)(.*?\S -)*(?:(?:.*?$)\n)?)*/gm, // - md
  numlist:
    /(^\s|\n|^)(\d)+(\.) (.*?\S *)*(?:(?:.*?$)\n)?((\s)*(\d)+(\.)(.*?\S *)*(?:(?:.*?$)\n)?)*/gm, // 1. md
  // /(^\s|\n|^)(\d)+(\.) (.*?\S *)*(?:(?:.*?$)\n)?((\s)*(\d)+(\.)(.*?\S *)*(?:(?:.*?$)\n)?)*/gm, // 1. md

  table:
    /[|]?(\s+[A-Za-z0-9 -_*#@$%:;?!.,\/\\]+\s+)[|]?[|]?(\s+[A-Za-z0-9 -_*#@$%:;?!.,\/\\]+\s+)[|]?[|]?(\s+[A-Za-z0-9 -_*#@$%:;?!.,\/\\]+\s+)[|]?\r?\n?/gm, // | md |

  // tag support's
  anchor_tag: /<a(>| .*?>)(.*?)<\/a>/g, // to match <a> tag
  txt_link: /<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/g, // text inside 'a' tag
  href: /href=(?:'|")(.*?)(?:'|") ?/g, // href inside a tag

  // vtypes
  gdrive_url: /(?:https:\/\/drive\.google\.com\/file\/d\/(.*?)\/view)/,
  vtype: /(?:vtype=(?:'|"|&quot;)(image|audio|video)(?:'|"|&quot;))/,
};

const encodeChars = (str) => {
  return str
    .replace(/\*/g, "&ast;")
    .replace(/\_/g, "&#95;")
    .replace(/\~/g, "&#126;");
};

const decodeChars = (str) => {
  return str
    .replace(/&ast;/g, "*")
    .replace(/&#95;/g, "_")
    .replace(/&#126;/g, "~");
};

const getDecodedText = (value) => {
  if (value && typeof value == "string") {
    value = value
      .replace(/&#39;|&#x27;/g, "'")
      .replace(/&quot;/g, '"')
      .replace(/&gt;/g, ">")
      .replace(/&lt;/g, "<")
      .replace(/&amp;/g, "&")
      .replace(/&#x2F;/g, "/")
      .replace(/&nbsp;/g, " ")
      .replace(/%2C/g, ",");
  }
  return value;
};

const setPrefixAndSuffix = (match, html) => {
  let prefix = "";
  let suffix = "";

  if (match[0] === "\n" || match[0] === " ") {
    prefix = match[0];
  }
  if (match[match.length - 1] === "\n" || match[match.length - 1] === " ") {
    suffix = match[match.length - 1];
  }
  return prefix + html + suffix;
};

// Regex to match single paired tags ex: br, hr etc..
const getSingleTagRegex = (tag) => {
  return new RegExp(`<${tag}.*?\/?>`, "gm");
};

export const parseUrl = (url) => {
  let result = url.match(rules.gdrive_url);
  if (result && result.length > 1 && result[1]) {
    return `http://docs.google.com/uc?export=open&id=${result[1]}`;
  }

  if (url.startsWith("@")) {
    let parts = url.split("@")[1].split(":");
    if (parts[0] === "gdrive") {
      return `http://docs.google.com/uc?export=open&id=${parts[1]}`;
    }
  }
  // let url = encodeURIComponent($1);
  return url;
};

const parseFileType = (alt = "", title = "", url = "", config = "") => {
  let vtype = "image"; // audio|video
  if (config.length) {
    let result = config.match(rules.vtype);
    if (result && result.length > 1 && result[1]) {
      vtype = result[1];
    }
  }

  if (vtype == "audio") {
    return `<audio vmd="audio" controls ${config}>
                <source src="${url}" type="audio/mp3"/>
              </audio>`;
  } else if (vtype == "video") {
    return `<video vmd="video" controls ${config}>
                <source src="${url}" type="video/mp4"/>
              </video>`;
  }

  return `<img vmd="image" src="${url}" alt="${alt}" title="${title}" ${config}>`;
};

const Markdown = {
  applyReverseMarkdown: (msg) => {
    msg = msg.replace(getSingleTagRegex("br"), "\n");
    msg = getDecodedText(msg);
    msg = Markdown.reverseAnchor(msg);
    msg = msg.replace(getSingleTagRegex("hr"), " --- ");
    msg = Markdown.reverseRecursive(msg);
    return msg.trim();
  },

  reverseRecursive: (msg) => {
    const domparser = new DOMParser();
    let domNodes = domparser.parseFromString(msg, "text/html").body.childNodes;
    let markdown = msg === "\n" ? "\n" : "";
    Array.from(domNodes).forEach((node) => {
      let isTextNode = node.nodeType === Node.TEXT_NODE;
      let tagName = !isTextNode && node.tagName.toLowerCase();
      const textContent = node.innerText;
      if (
        (textContent && (textContent.trim() || textContent.includes("\n"))) ||
        isTextNode ||
        tagName === "br"
      ) {
        if (isTextNode) {
          return (markdown += node.data);
        }
        let replacer = MARKDOWN_TAGS[tagName] || "";
        let content = node.childNodes.length
          ? Markdown.reverseRecursive(node.innerHTML)
          : textContent;
        markdown += !content.trim() && content.includes("\n") ? "\n" : "";
        let hasNextLine = content[content.length - 1] === "\n";
        let postReplacer = SINGLE_REPLACE_TAGS.includes(tagName)
          ? ""
          : replacer;
        markdown +=
          replacer +
          (hasNextLine
            ? content.slice(0, content.length - 1) + postReplacer + "\n"
            : content + postReplacer);
      }
    });
    return markdown;
  },

  reverseAnchor: (msg) => {
    let { anchor_tag, href } = rules;
    return msg.replace(anchor_tag, (match, $1, $2) => {
      const anchorText = $2;
      let hrefValue;
      $1.replace(href, (match, $1) => {
        hrefValue = $1;
      });
      if (hrefValue.includes("mailto:")) {
        return hrefValue.replace("mailto:", "");
      }
      return `${
        msg[msg.indexOf(match) - 1] !== " " ? " " : ""
      }[${anchorText}](${encodeURI(hrefValue)})`;
    });
  },

  // md-apply
  applyMarkdown: (msg, skip_media = false) => {
    if (typeof msg !== "string") {
      return msg;
    }

    msg = getDecodedText(msg);
    msg = msg
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/<br>/g, "\n")
      .trim();

    msg = Markdown.applyRecursive(msg, skip_media);
    msg = Markdown.applyOnce(msg);
    msg = Markdown.applyNextLine(msg);
    msg = decodeChars(msg);
    return '<div class="vmd">' + msg + "</div>";
  },

  applyRecursive: (msg, skip_media = false) => {
    msg = msg.replace(rules.bold, Markdown.getBoldHTML);
    msg = msg.replace(rules.italic, Markdown.getItalicHTML);
    msg = msg.replace(rules.underline, Markdown.getUnderLineHTML);
    msg = msg.replace(rules.strike, Markdown.getStrikeHTML);
    msg = msg.replace(rules.line, Markdown.getLineHTML);

    msg = msg.replace(rules.bullet, Markdown.getBulletHTML);
    msg = msg.replace(rules.numlist, Markdown.getNumlistHTML);

    if (!skip_media) {
      msg = msg.replace(rules.video, Markdown.getVideoHTML);
      msg = msg.replace(rules.image, Markdown.getImageHTML);
    }
    msg = msg.replace(rules.link, Markdown.getLinkHTML);
    return msg;
  },

  applyOnce: (msg) => {
    msg = msg.replace(rules.blockquote, Markdown.getBlockQuoteHTML);
    msg = msg.replace(rules.heading, Markdown.getHeadingHTML);
    msg = msg.replace(rules.codeblock, Markdown.getCodeblockHTML);
    msg = msg.replace(rules.monospace, Markdown.getMonoSpaceHTML);
    return msg;
  },

  applyNextLine: (msg) => {
    return msg.replace(rules.nextline, Markdown.getNextLineHTML);
  },

  // md-helper
  getBoldHTML: (match, $1, $2) => {
    if (
      !$2 ||
      !$2.match(/[^_*`~]/) ||
      $2[0] === "*" ||
      $2[$2.length - 1] === "*" ||
      ($2[0] === " " && $2[$2.length - 1] === " ")
    ) {
      return match;
    }
    if (!$2) {
      return match;
    }
    return (
      $1 + ' <strong vmd="bold">' + Markdown.applyRecursive($2) + "</strong> "
    );
  },

  getItalicHTML: (match, $1, $2) => {
    if (
      !$2 ||
      !$2.match(/[^_*`~]/) ||
      $2[0] === "_" ||
      $2[$2.length - 1] === "_" ||
      ($2[0] === " " && $2[$2.length - 1] === " ")
    ) {
      return match;
    }
    if (!$2) {
      return match;
    }
    return $1 + ' <i vmd="italic">' + Markdown.applyRecursive($2) + "</i> ";
  },

  getUnderLineHTML: (match, $1, $2) => {
    if (
      !$2 ||
      !$2.match(/[^_*`~]/) ||
      $2[0] === "__" ||
      $2[$2.length - 1] === "__" ||
      ($2[0] === " " && $2[$2.length - 1] === " ")
    ) {
      return match;
    }
    if (!$2) {
      return match;
    }
    return $1 + ' <u vmd="underline">' + Markdown.applyRecursive($2) + "</u>";
  },

  getStrikeHTML: (match, $1, $2) => {
    if (
      !$2 ||
      !$2.match(/[^_*`~]/) ||
      $2[0] === "~" ||
      $2[$2.length - 1] === "~" ||
      ($2[0] === " " && $2[$2.length - 1] === " ")
    ) {
      return match;
    }
    if (!$2) {
      return match;
    }
    return (
      $1 + ' <strike vmd="strike">' + Markdown.applyRecursive($2) + "</strike> "
    );
  },

  getHeadingHTML: (match, $1, $2) => {
    let no_of_hash = $1.length;
    return setPrefixAndSuffix(
      match,
      " <h" +
        no_of_hash +
        ` vmd="h${no_of_hash}">` +
        encodeChars($2) +
        "</h" +
        no_of_hash +
        "> "
    );
  },

  getBlockQuoteHTML: (match, $1) => {
    $1 = $1.trim();
    if (!$1.length) {
      return match;
    }
    return setPrefixAndSuffix(
      match,
      ' <blockquote vmd="blockquote">' +
        "<p>" +
        Markdown.applyRecursive($1) +
        "</p>" +
        "</blockquote> "
    );
  },

  getMonoSpaceHTML: (match, $1, $2) => {
    if (
      !$2 ||
      !$2.match(/[^_*`~]/) ||
      $2[0] === "`" ||
      $2[$2.length - 1] === "`" ||
      ($2[0] === " " && $2[$2.length - 1] === " ")
    ) {
      return match;
    }
    return (
      $1 +
      ' <code vmd="monospace" class="code">&ldquo;' +
      encodeChars($2) +
      "&rdquo;</code> "
    );
  },

  getCodeblockHTML: (match, $1, $2) => {
    if ($2.trim().length === 0) {
      return match;
    }
    return (
      $1 + ' <pre vmd="codeblock"> ' + Markdown.applyRecursive($2) + "</pre>"
    );
  },

  getNextLineHTML: (match, $1, $2, $3) => {
    return $1 + ' <br vmd="br" > ' + $3;
  },

  getLineHTML: (match, $1, $2, $3) => {
    return (
      $1 +
      '<hr vmd="line" class="hr w100">' +
      Markdown.applyRecursive($3.trim())
    );
  },

  getLinkHTML: (match, $1, $2, $3, $4) => {
    var url = $3.startsWith("www.") ? "http" + "://" + $3 : $3;
    return (
      $1 +
      ` <a vmd="link" href="${encodeURI(
        url
      )}" rel="noopener noreferrer" ${$4}>${encodeChars($2)}</a>`
    );
  },

  getImageHTML: (match, $1, $2, $3, $4) => {
    let file_tag = parseFileType($1, $3, parseUrl($2), $4);
    return setPrefixAndSuffix(match, file_tag);
  },

  getVideoHTML: (match, $1, $2, $3, $4, $5, $6, $7) => {
    let file_tag = parseFileType($1, $3, parseUrl($2), $4);
    return setPrefixAndSuffix(
      match,
      `<a vmd="hyperlink" href="${$5}" title="${$6}" rel="noreferrer" ${$7}>${file_tag}</a>`
    );
  },

  getBulletHTML: (match) => {
    if (!isNaN(match)) {
      return match;
    } else if (match.replace(/\s/g, "").replace(/\*/g, "").length == 0) {
      return match;
    }

    const parseListItem = (match) => {
      let list_item_html = "";

      var line = match.split("\n");
      for (var i = 0; i < line.length; i++) {
        line[i] = line[i].replace(/[\-|●|○]/, "-");
        if (line[i].includes("-")) {
          list_item_html +=
            line[i].replace(/\-/, ' <li style="list-style: inherit"> ') +
            "</li> ";
        }
      }
      return list_item_html;
    };

    return setPrefixAndSuffix(
      match,
      ' <ul vmd="list-bullet" style="list-style-type: disc; list-style: inherit;">' +
        parseListItem(match) +
        "</ul> "
    );
  },

  getNumlistHTML: (match) => {
    if (!isNaN(match)) {
      return match;
    }

    const parseListItem = (match) => {
      let list_item_html = "";

      var line = match.split("\n");
      for (var i = 0; i < line.length; i++) {
        line[i] = line[i].replace(/(\d)*(\.)/, "-");
        if (line[i].includes("-")) {
          list_item_html +=
            line[i].replace(/\-/, '<li style="list-style: decimal"> ') +
            "</li>";
        }
      }
      return list_item_html;
    };

    return setPrefixAndSuffix(
      match,
      ' <ol vmd="list-number">' + parseListItem(match) + "</ol> "
    );
  },
};

export default Markdown;
