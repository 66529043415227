import store from "@/store.js";

function getPropValue(type, key) {
  return store.state[type][key] ? store.state[type][key] : key;
}

export const goTo = (slug) => {
  if (!slug) {
    return;
  }
  let path = `/blogs/${slug}`;
  if (window.location.pathname === escape(path)) {
    return;
  }
  // this.$router.push({ name: "Blogs", params: { slug } });
  window.location.pathname = `/blogs/${slug}`;
};

export const $i18N = (key, ...params) => {
  let value = "";
  let searchKey = ("" + key).toLowerCase();

  if (store.state.i18n[searchKey]) {
    value = store.state.i18n[searchKey];
  } else {
    return key;
  }

  params.forEach((param, i) => {
    let regexp = new RegExp("\\$" + (i + 1), "g");
    value = value.replace(regexp, param);
  });
  return value;
};

export const $link = (key) => {
  return getPropValue("link", key);
};

export const $info = (key) => {
  return getPropValue("info", key);
};
